// export default SliderOne
import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Col, Container, Row } from "react-bootstrap"
import { SliderOneData } from "../data"
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper"

export default function ClientCarousel() {
  return (
    <>
      <section className="main-carousel">
        <Swiper
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          watchOverflow={true}
          loop={true}
          loopFillGroupWithBlank={true}
          navigation={true}
          modules={[Navigation, Autoplay]}
          className=""
        >
          {SliderOneData.map(({ image, subText, title, button }, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt="Awesome Image" />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </>
  )
}
