import { Link } from "gatsby"
import React from "react"
import serviceTwo1 from "../images/natnepal/handiCraft/DSC_3310.jpg"

const ServiceTwo = () => {
  return (
    <section className="what-we-do sec-pad">
      <div className="thm-container">
        <div className="sec-title text-center">
          <span>Our features</span>
          <h3>What We Do</h3>
          <p>
            There are many variations of woolen products available, but
            <br /> the majority have suffered alteration in some form.
          </p>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="single-what-we-do">
              <div className="img-box">
                <img width={570} height={320} src={serviceTwo1} alt="" />
              </div>
              <div className="text-box hvr-bounce-to-bottom">
                <a href="/service-details">
                  <h3>All Kinds of Woolen Service</h3>
                </a>
                <p>
                  We provide affordable woolen at the highest quality with
                  competitive price. Our services includes all woolen and
                  handicraft services.
                </p>
                <Link
                  to="/services"
                  className="read-more fa fa-angle-right"
                ></Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="row">
              <div className="col-sm-6">
                <div className="single-what-we-do-one hvr-bounce-to-bottom">
                  <i className="printify-icon-paper-drill"></i>
                  <Link to="/services">
                    <h3>Woolen Products</h3>
                  </Link>
                  <p>
                    We do our own manufacturing of various woolen products. We
                    have been manufacturing our Woolen knitwears, gloves, socks
                    etc.
                  </p>
                  <Link
                    to="/services"
                    className="read-more fa fa-angle-right"
                  ></Link>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="single-what-we-do-one hvr-bounce-to-bottom">
                  <i className="printify-icon-printing-text"></i>
                  <Link to="/services">
                    <h3>HandiCraft Products</h3>
                  </Link>
                  <p>
                    We do our own metal and wooden carving, make Thanka
                    paintings. And as a result of which we take pride in our
                    products and take much more orders with fulfillment
                    guarantee than other exporters.
                  </p>
                  <Link
                    to="/services"
                    className="read-more fa fa-angle-right"
                  ></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServiceTwo
